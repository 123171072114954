* {
    margin: 0px;
    padding: 0px;
}

a {
    text-decoration: none !important;
}

.preloader {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    color: #333;
  }
  

p {
    margin-bottom: 0px !important;
}

.row {
    --bs-gutter-x: 0rem !important;
}

.landingPage {
    background: #ecebe5;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.textContainer {
    width: 50.624vw !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.imagesPart {
    width: 43.976vw !important;
}

.iconsDiv {
    width: 5.4vw !important;
    position: relative;
    position: relative;
}

.textContainer .flexDiv {
    padding-left: 13%;
    padding-top: 100px;
}

.textContainer .textDiv {
    border-left: 1px solid #9b9a96;
    padding-left: 2.38%;
}

.textContainer .textDiv p {
    font-family: 'Inter', system-ui;
    padding-left: 5px;
    padding-top: 5.2% !important;
    font-size: 24px;
    font-weight: lighter;
    color: #333;
    line-height: 40px;
    opacity: 0.95;
}

.textContainer .buttonDiv {
    position: absolute;
    bottom: 100px;
}

.textContainer .buttonDiv p {
    font-family: 'Inter', system-ui;
    padding-top: 1.63% !important;
    font-size: 20px;
    font-weight: 100;
    color: #333;
    line-height: 28px;
    opacity: 0.95;
}

.textContainer .textDiv img {
    height: 32px;
    width: 205px;
    flex-shrink: 0;
    fill: var(--primary-black, #2E2E2E);
    stroke-width: 1px;
    stroke: #333;
}

.textContainer .buttonDiv input {
    background: #dcdbd5;
    outline: none;
    border: none;
    padding: 12px 12px;
    width: 312px;
    border-radius: 2px;
    margin-right: 4px;
}

.textContainer .buttonDiv input::placeholder {
    font-size: 18px;
    font-family: 'Inter', system-ui;
    color: #8A8A8D;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.textContainer .buttonDiv button {
    width: 100%;
    min-width: 132px !important;
    color: var(--primary-beige, #ECEBE5);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 12px;
    color: white;
    background: #2e2e2e;
    outline: #2e2e2e;
    border: none;
    border-radius: 2px;
}

/* ############### side box styling start #############  */

.BoxDiv {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.boxes {
    height: 47.657vh !important;
    margin-top: 16px;
}

.bottom-area {
    padding-bottom: 16px !important;
}

.boxes .main-glass-img {
    min-width: 635px !important;
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    background-image: url(/src/assests/images/background-frame.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 0px 51.5px !important;
}

.boxes p {
    color: var(--primary-black, #ECEBE5);
    font-family: 'Inter', sans-serif;
    font-size: 125%;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: lighter;
    animation: fadeIn 3s ease forwards;
    display: block;
    padding-top: 44.5px !important;
}

.boxes .glassImg {
    width: 488px !important;
    height: 151px !important;
    object-fit: contain;
    position: absolute;
    top: 54%;
    left: 51%;
    transform: translate(-50%, -50%);
    animation: fadeIn 3s ease forwards;
}

.boxes a {
    color: var(--primary-black, #ECEBE5);
    font-family: 'Inter', sans-serif;
    line-height: 19px;
    font-size: 16px;
    text-transform: none !important;
    font-weight: 300;
    text-decoration: underline;
}

.boxes .launcherImg {
    min-width: 635px !important;
    width: 100%;
    height: 100%;
    background-image: url('../../assests/images/minimis-mobile-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    animation: fadeIn 3s ease forwards;
    padding: 0px 51.5px !important;
}


.boxes .mobileImg {
    width: 100% !important;
    height: auto !important;
    max-width: 700px !important;
    margin-top: -30px !important;
    object-fit: contain;
    position: absolute;
    top: 54%;
    left: 51%;
    transform: translate(-50%, -50%);
    animation: fadeIn 3s ease forwards;
}

#icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.iconsDiv div {
    font-size: 20px;
    cursor: pointer;
    margin: 7px 0px;
}

hr {
    margin: 0 0 !important;
    color: #2E2E2E !important;
    border: 0 !important;
    border-top: 0 !important;
    opacity: 0.45 !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* pages.css */

.BoxDiv {
    overflow: hidden;
}

.boxes-div {
    height: 47.657vh !important;
    margin-top: 16px;
}

.glassHoverDiv {
    min-width: 635px !important;
    background: #DAD8CC;
    height: 47.657vh;
    padding: 42px 0px 0px 42px;
    display: flex;
    flex-direction: column !important;
    overflow: hidden;
    gap: 28px !important;
    box-sizing: border-box;
    border-left: 2px solid #333;
}

.glassHoverDiv h2,
.launcherHoverDiv h2 {
    color: var(--primary-black, #2E2E2E);
    font-family: 'Inter', system-ui;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 12px;
    animation: fadeIn 3s ease forwards;
}

.glassHoverDiv .hoverText {
    width: 44%;
    min-height: 270px !important;
    height: 279px !important;
    max-height: auto;
}

.glassHoverDiv div .glassImg1 {
    width: 56%;
    padding-top: 30px;
    display: flex;
    align-items: start;
    justify-content: center;
}

.glassHoverDiv .glassImg1 img {
    height: 102px !important;
    width: 301px !important;
    object-fit: cover;
    animation: fadeIn 3s ease forwards;
}

.paragraph-heading {
    width: 70% !important;
}

.paragraph-heading p {
    font-family: 'Inter', system-ui;
    color: var(--primary-black, #333);
    font-weight: 300;
    line-height: 30px;
    font-size: 21px !important;
    animation: fadeIn 3s ease forwards;
}

.launcherHoverDiv .hoverText p,
.glassHoverDiv .hoverText p {
    font-family: 'Inter', system-ui;
    color: var(--primary-black, #2E2E2E);
    font-weight: 300;
    line-height: 23px;
    font-style: normal;
    font-size: 16px;
    animation: fadeIn 3s ease forwards;
    opacity: 0.7;
}

.hoverText .p1 {
    margin-top: 0px;
}

.hoverText .p2 {
    margin-top: 28px;
}

.hoverText .p3 {
    margin-top: 0px;
}

.hoverText .p4 {
    margin-top: 28px;
}

.glassHoverDiv .hoverText button {
    outline: none;
    border: none;
    font-family: 'Inter', system-ui;
    padding: 3.65% 5.0%;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    background: var(--primary-black, #2E2E2E);
    font-weight: 500;
    margin-top: 26px !important;
    animation: fadeIn 3s ease forwards;
}

.launcherHoverDiv .hoverText button {
    outline: none;
    border: none;
    font-family: 'Inter', system-ui;
    padding: 3.65% 5.0%;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    background: var(--primary-black, #2E2E2E);
    font-weight: 500;
    margin-top: 51px !important;
    animation: fadeIn 3s ease forwards;
}

.launcherHoverDiv {
    min-width: 635px !important;
    height: 47.657vh;
    padding: 42px 0px 0px 42px;
    display: flex;
    flex-direction: column !important;
    gap: 28px !important;
    box-sizing: border-box;
    overflow: hidden;
    background: #DAD8CC;
    border-left: 2px solid #333;
}

.launcherHoverDiv div .hoverText {
    width: 45%;
    min-height: 258px !important;
    height: 258px !important;
    max-height: 100%;
}

.launcherHoverDiv div .launcherImg1 {
    width: 55%;
    display: flex;
    justify-content: flex-end;
}

.launcherHoverDiv .launcherImg1 .phone-mockup-class {
    background-image: url("../../assests/images/mobile-text-img-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 320px;
    width: 401px;
    margin-top: -30px;
    animation: fadeIn 3s ease forwards;
}

.minimisLogo {
    width: 95%;
    height: auto;
}

/*Tab View Css */

.tabLauncherMobileDesign .tabadditionalButton button,
.tabglassMobileDesign .tabadditionalButton button {
    background: none;
    outline: none;
    border: none;
    color: #9b9a96;
    font-size: 12px;
    cursor: pointer;
}

.tabadditionalText {
    margin-left: 16px;
    padding-top: 22px;
    position: relative;
    animation: fadeIn 1s forwards;
}

.tabadditionalText p {
    color: #333;
    font-family: 'Inter', system-ui;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    opacity: 0.7;
}

.tabadditionalButton p {
    color: var(--primary-black, #2E2E2E);
    font-family: 'Inter', system-ui;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    width: 97%;
    line-height: 18px;
    opacity: 0.7;
    margin-left: 16px;
}

.tabadditionalText h6 {
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
    color: var(--primary-black, #2E2E2E);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tabglassMobileDesign {
    background: #DAD8CC;
    width: 100%;
    position: relative;
    height: 420px !important;
    overflow: hidden;
    transition: height 0.3s ease;
}

.tabLauncherMobileDesign {
    background: #DAD8CC;
    width: 100%;
    position: relative;
    height: 570px;
    overflow: hidden;
    transition: height 0.3s ease;
}

.tabLauncherMobileDesign .tabimgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabLauncherMobileDesign img {
    width: 50% !important;
    height: auto !important;
    margin: 20px 0px;
}

.tabglassMobileDesign img {
    width: 60%;
    height: auto !important;
    object-fit: contain;
    display: flex;
    justify-content: center;
}

.tabadditionalButton input {
    font-family: "Inter", system-ui;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 2px;
    background: var(--primary-black, #2E2E2E);
    color: var(--primary-beige, #ECEBE5);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

/* Mobile View CSS*/

.mobileView {
    background: #ecebe5;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden !important;
}

.mobileView .flexDiv {
    padding: 0px 18px;
}

.mobileView .headingDiv {
    padding: 24px 16px 24px 16px;
    background: #ecebe5;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.mobileView .headingDiv h6 {
    font-family: 'Inter', sans-serif;
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding-bottom: 8px;
}

.mobileView .flexDiv {
    padding-top: 8px;
}

.mobileView .flexDiv .textDiv {
    border-left: 1px solid #333;
    padding-left: 15px;
    font-size: 16px;
    line-height: 22px;
    font-family: "Inter", system-ui;
    font-weight: 200 !important;
    letter-spacing: 0%;
    margin-top: 62px;
    opacity: 0.9;
}

.box1Mobile {
    background-image: url(/src/assests/images/background-frame.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: inline-block;
    height: 252px;
    width: 100%;
    position: relative;
    transition: height 0.2s ease;
}

.box2Mobile {
    background-image: url('../../assests/images/minimis-mobile-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    height: 252px;
    width: 100%;
    position: relative;
    transition: height 0.2s ease;
}

.glassMobileDesign {
    background-color: #DAD8CC;
    width: 100%;
    position: relative;
    height: 420px;
    overflow: hidden;
    transition: height 0.3s ease;
}

.LauncherMobileDesign {
    background-color: #DAD8CC;
    width: 100%;
    position: relative;
    height: 530px;
    overflow: hidden;
    transition: height 0.3s ease;
}

.box2Mobile p,
.box1Mobile p {
    color: #ECEBE5 !important;
    display: block;
    font-family: "Inter", system-ui;
    font-size: 15px;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-top: 20px !important;
    padding-left: 20px;
    text-transform: uppercase;
    animation: fadeIn 1s forwards;
    position: relative;
    z-index: 10000;
}

.box1Mobile img {
    height: 121px;
    width: 70%;
    object-fit: contain;
    top: 55%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    animation: fadeIn 1s forwards;
}

.box2Mobile img {
    height: 100%;
    width: 70%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    animation: fadeIn 1s forwards;
}

.inputBtnDiv {
    background: #ecebe5;
    width: 100vw;
    z-index: 1000;
    position: relative;
}

.inputBtnDiv p {
    padding: 30px 16px;
    color: #333;
    padding-left: 15px;
    font-size: 16px;
    line-height: 22px;
    font-family: "Inter", system-ui;
    font-weight: 200 !important;
    letter-spacing: 0%;
    opacity: 0.9;
}

.BTnDiv {
    width: 100% !important;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BTnDiv .inputDiv {
    width: 62% !important;
}

.BTnDiv .inputDiv input {
    background: #dcdbd5;
    border: none;
    border-radius: 2px;
    margin-right: 4px;
    outline: none;
    padding: 12px 12px;
    width: 91%;
    margin-left: 16px;
}

.BTnDiv div input::placeholder {
    color: #8A8A8D;
    font-family: 'Inter', system-ui;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}

.BTnDiv .getInDiv {
    width: 38% !important;
}

.BTnDiv div button {
    color: var(--primary-beige, #ECEBE5);
    text-align: center;
    font-family: 'Inter', system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 2px;
    background: var(--primary-black, #2E2E2E);
    padding: 12px;
    width: 90%;
    height: 48px !important;
    outline: none;
    border: none;
}

.MobileiconsDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MobileiconsDiv div {
    cursor: pointer;
    font-size: 20px;
    margin: 0px 10px 20px 10px
}

.box1Mobile div button,
.box2Mobile div button {
    position: absolute;
    bottom: 4px;
    right: 7px;
    background: none;
    outline: none;
    border: none;
    color: #000000 !important;
    opacity: 0.8;
    font-size: 12px;
    cursor: pointer;
}

.LauncherMobileDesign .additionalButton button,
.glassMobileDesign .additionalButton button {
    background: none;
    outline: none;
    border: none;
    height: 41px;
    color: #9b9a96;
    font-size: 12px;
    cursor: pointer;
}

.additionalText {
    margin-left: 16px;
    margin-top: 20px;
    margin-right: 16px;
    position: relative;
    animation: fadeIn 1s forwards;
}

.additionalText p {
    color: #333;
    font-family: "Inter", system-ui;
    font-size: 16px;
    font-weight: 300;
    width: 97%;
    opacity: 0.7;
    line-height: normal
}

.additionalButton {
    margin: 0px 16px;
}

.additionalButton p {
    color: #2e2e2e;
    font-family: "Inter", system-ui;
    font-size: 14px;
    font-weight: 300;
    width: 97%;
    line-height: 18px;
    opacity: 0.7;
}

.additionalText h6 {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--primary-black, #2E2E2E);
    font-family: "Inter", system-ui;
}

.LauncherMobileDesign .imgDiv {
    display: flex;
    justify-content: center;

}

.LauncherMobileDesign img {
    width: 65% !important;
    height: auto !important;
    margin-top: 11px;
    object-fit: fill;
    margin-bottom: 23px;
}

.glassMobileDesign img {
    width: 60%;
    align-items: center;
    object-fit: contain !important;
    height: auto;
    margin-bottom: 10px !important;
}

.additionalButton input {
    font-family: "Inter", system-ui;
    padding: 10px;
    outline: none;
    border: none;
    border-radius: 2px;
    background: var(--primary-black, #2E2E2E);
    color: var(--primary-beige, #ECEBE5);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

/* ########## modal styling start ########### */

.modal-backdrop {
    --bs-backdrop-bg: #2E2E2E !important;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.modal-dialog {
    width: 465px !important;
}

.modal-body {
    border: 0px solid var(--Stroke-light, #DEE2E6);
    background: var(--primary-beige, #ECEBE5);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    border-radius: 7px;
    flex-direction: column;
    justify-content: center;
    position: relative !important;
    z-index: 800000 !important;
    padding: 36px 40px !important;
}

.modal-body .thank-you-heading {
    color: var(--primary-black, #2E2E2E);
    font-family: "Inter", system-ui;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px !important;
    text-align: center !important;
}

.modal-body .thank-you-touch {
    color: var(--primary-black, #2E2E2E);
    font-family: "Inter", system-ui;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 24px !important;
    text-align: center !important;
}

.modal-body .thank-you-img {
    width: 100%;
    height: 81px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center !important;
}

.modal-body .thank-you-img img {
    width: 81px;
    height: 81px;
}

.modal-body .dropdown-heading {
    color: var(--primary-black, #2E2E2E);
    font-family: "Inter", system-ui;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    text-align: center !important;
    margin-bottom: 24px;
}

.modal-body .dropdown-list {
    color: var(--primary-black, #2E2E2E);
    font-family: "Inter", system-ui;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 8px !important;
}

.modal-body .modal-dropdown {
    width: 100% !important;
    height: 48px !important;
    background-color: #DCDBD5 !important;
    display: flex;
    flex-direction: row;
    text-align: start !important;
    padding: 0 0 0 12px;
    border: 0px !important;
}

.modal-body .dropdown-optional-text {
    width: 100% !important;
    height: 92px !important;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    background-color: #DCDBD5 !important;
}

.modal-body .dropdown-optional-text textarea {
    width: 100% !important;
    height: 100% !important;
    padding: 12px 12px 0 12px;
    background-color: #DCDBD5 !important;
    border: 0px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

:focus-visible {
    outline: 0px;
}


.modal-body div {
    color: var(--primary-black, #2E2E2E);
    /* text-align: center; */
    font-family: "Inter", system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.modal-body button {
    border-radius: 2px;
    background: var(--primary-black, #2E2E2E);
    display: flex;
    height: 46px;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: var(--Grey-Light, #F5F5F5);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Inter", system-ui;
    border: none;
    outline: none;
    width: 100%;
}

.modal-content {
    border-radius: 3px !important;
}

@media screen and (min-width: 1400px) and (min-height: 800px) and (max-height: 980px) {

    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 10%;
        padding-top: 10%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.8% !important;
        font-size: 23px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 35px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-top: 1.63% !important;
        font-size: 19px;
        letter-spacing: 1px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 12px 12px;
        width: 312px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 18px;
        font-family: 'Inter', sans-serif;
        color: #8A8A8D;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 12px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 51.5px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 44.5px !important;
    }

    .boxes .glassImg {
        width: 488px !important;
        height: 151px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 51.5px !important;
    }

    .boxes .mobileImg {
        width: 82% !important;
        max-width: 620px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: 635px !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 30px 0px 0px 30px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 18px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 32px;
        font-weight: 400;
        line-height: 30px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 44%;
        min-height: 235px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 56%;
        padding-top: 15px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .glassHoverDiv .glassImg1 img {
        height: 102px !important;
        width: 301px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 26px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 20px;
        font-style: normal;
        font-size: 15.5px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 18px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 18px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: 635px !important;
        height: 47.657vh;
        padding: 30px 0px 0px 30px;
        display: flex;
        flex-direction: column !important;
        gap: 18px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 45%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        width: 385px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1400px) and (min-height:731px) and (max-height:799px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.7% !important;
        font-size: 23px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-top: 1.63% !important;
        font-size: 19px;
        letter-spacing: 1px;
        font-weight: lighter;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 13px;
    }

    .bottom-area {
        padding-bottom: 13px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 51.5px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 44.5px !important;
    }

    .boxes .glassImg {
        width: 340px !important;
        height: 130px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 51.5px !important;
    }

    .boxes .mobileImg {
        width: 80% !important;
        max-width: 530px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 13px;
    }

    .glassHoverDiv {
        min-width: 635px !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 26px 0px 0px 26px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 28px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 44%;
        min-height: 210px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 56%;
        padding-top: 15px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .glassHoverDiv .glassImg1 img {
        height: 102px !important;
        width: 301px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 26px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 20px;
        font-style: normal;
        font-size: 15.5px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 16px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 16px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 16px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: 635px !important;
        height: 47.657vh;
        padding: 26px 0px 0px 26px;
        display: flex;
        flex-direction: column !important;
        gap: 18px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 45%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        width: 350px;
        margin-top: -50px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1400px) and (min-height:690px) and (max-height:730px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv img {
        height: 32px;
        width: 205px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.7% !important;
        font-size: 21px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        font-size: 18px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 13px;
    }

    .bottom-area {
        padding-bottom: 13px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 51.5px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 44.5px !important;
    }

    .boxes .glassImg {
        width: 340px !important;
        height: 130px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 51.5px !important;
    }

    .boxes .mobileImg {
        width: 70% !important;
        max-width: 535px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 13px;
    }

    .glassHoverDiv {
        min-width: 635px !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 26px 0px 0px 26px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 26px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 44%;
        min-height: 210px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 56%;
        padding-top: 15px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .glassHoverDiv .glassImg1 img {
        height: 102px !important;
        width: 301px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 20px;
        font-style: normal;
        font-size: 14.5px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 16px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 16px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 30px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: 635px !important;
        height: 47.657vh;
        padding: 26px 0px 0px 26px;
        display: flex;
        flex-direction: column !important;
        gap: 18px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 45%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        width: 350px;
        margin-top: -50px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1400px) and (min-height:600px) and (max-height:689px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.3% !important;
        font-size: 20px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 10px;
    }

    .bottom-area {
        padding-bottom: 13px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 47.657vh;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 35px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 32px !important;
    }

    .boxes .glassImg {
        width: 300px !important;
        height: 105px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 35px !important;
    }

    .boxes .mobileImg {
        width: 60% !important;
        max-width: 460px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -20px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 10px;
    }

    .glassHoverDiv {
        min-width: 635px !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 12px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 26px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 60%;
        min-height: 163px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 40%;
        padding-top: 15px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 95px !important;
        width: 260px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 18px;
        font-style: normal;
        font-size: 14.5px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 12px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 12px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 16px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 30px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: 635px !important;
        height: 100%;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 12px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 50%;
        min-height: 240px !important;
        height: 240px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 250px;
        width: 300px;
        margin-top: -60px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1400px) and (max-height:599px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.3% !important;
        font-size: 20px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 35px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 22px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 10px;
    }

    .bottom-area {
        padding-bottom: 13px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 47.657vh;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 25px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 100%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 20px !important;
    }

    .boxes .glassImg {
        width: 250px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 25px !important;
    }

    .boxes .mobileImg {
        width: 57% !important;
        max-width: 430px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -30px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 10px;
    }

    .glassHoverDiv {
        min-width: 635px !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 12px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 26px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 60%;
        min-height: 163px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 40%;
        padding-top: 15px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 85px !important;
        width: 230px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 14.5px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 12px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 24px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: 635px !important;
        height: 100%;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 12px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 58%;
        min-height: 240px !important;
        height: 240px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 42%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 210px;
        width: 275px;
        margin-top: -60px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) and (min-height:930px) {

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 51.5px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 44.5px !important;
    }

    .boxes .glassImg {
        width: 450px !important;
        height: 140px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 51.5px !important;
    }

    .boxes .mobileImg {
        width: 90% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 32px 0px 0px 32px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 28px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 270px !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 95px !important;
        width: 255px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 30px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 23px;
        font-style: normal;
        font-size: 16px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 28px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 28px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 26px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 51px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 32px 0px 0px 32px;
        display: flex;
        flex-direction: column !important;
        gap: 28px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 45%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        width: 340px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }

    .minimisLogo {
        width: 95%;
        height: auto;
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) and (min-height:800px) and (max-height:929px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 80px;
        padding-top: 80px;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 23px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 19px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 15px;
    }

    .bottom-area {
        padding-bottom: 15px !important;
    }

    .boxes .main-glass-img {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 51.5px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 44.5px !important;
    }

    .boxes .glassImg {
        width: 450px !important;
        height: 140px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: 635px !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 51.5px !important;
    }

    .boxes .mobileImg {
        width: 520px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 15px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 28px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 32px;
        font-weight: 400;
        line-height: 26px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: auto !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 95px !important;
        width: 255px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 26px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 20px;
        font-style: normal;
        font-size: 16px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 20px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 20px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 22px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 42px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 28px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 45%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        width: 340px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }

    .minimisLogo {
        width: 95%;
        height: auto;
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) and (min-height:731px) and (max-height:799px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 21px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 53px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 15px;
    }

    .bottom-area {
        padding-bottom: 15px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 51.5px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 125%;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 44.5px !important;
    }

    .boxes .glassImg {
        width: 450px !important;
        height: 140px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 51.5px !important;
    }

    .boxes .mobileImg {
        width: 470px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 15px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 20px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 28px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: auto !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 95px !important;
        width: 255px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 22px;
        font-size: 19px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 19px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 18px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 18px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 3.65% 5.0%;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 36px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 20px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 45%;
        min-height: 235px !important;
        height: 235px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
        width: 340px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) and (min-height:690px) and (max-height:730px) {

    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.1% !important;
        font-size: 21px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 53px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 15px;
    }

    .bottom-area {
        padding-bottom: 15px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 32px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 28px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 120px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 16px;
        font-size: 15px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 32px !important;
    }

    .boxes .mobileImg {
        width: 430px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 15px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 16px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 55%;
        min-height: 200px !important;
        height: 203px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 45%;
        padding-top: 0px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .glassHoverDiv .glassImg1 img {
        height: 85px !important;
        width: 225px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 22px;
        font-size: 19px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 18px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 16px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 16px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 16px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 24px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 16px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 55%;
        min-height: 210px !important;
        height: 212px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 270px;
        width: 320px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) and (min-height:600px) and (max-height:689px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 7%;
        padding-top: 7%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.1% !important;
        font-size: 18px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 46px;
    }

    .textContainer .textDiv img {
        height: 25px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 15px;
    }

    .bottom-area {
        padding-bottom: 15px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 32px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 28px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 120px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 16px;
        font-size: 15px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 32px !important;
    }

    .boxes .mobileImg {
        width: 410px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -20px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 15px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 22px;
        font-weight: 400;
        line-height: 10px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 60%;
        min-height: 180px !important;
        height: 185px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 40%;
        padding-top: 0px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .glassHoverDiv .glassImg1 img {
        height: 77px !important;
        width: 200px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 17px;
        font-size: 17px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 13px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 14px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 14px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 16px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 24px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 14px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 55%;
        min-height: 190px !important;
        height: 195px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 225px;
        width: 295px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) and (max-height:599px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 6%;
        padding-top: 6%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 2.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 26px;
        opacity: 0.95;
    }

    .textDiv img {
        height: 24px;
        width: 160px;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 22px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 15px;
    }

    .bottom-area {
        padding-bottom: 15px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 32px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 28px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 16px;
        font-size: 15px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 32px !important;
    }

    .boxes .mobileImg {
        width: 410px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -20px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 15px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 20px;
        font-weight: 400;
        line-height: 5px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 60%;
        min-height: 180px !important;
        height: 185px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 40%;
        padding-top: 0px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .glassHoverDiv .glassImg1 img {
        height: 77px !important;
        width: 200px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 70% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 17px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 14px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 10px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 24px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 55%;
        min-height: 190px !important;
        height: 195px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 210px;
        width: 270px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1100px) and (max-width:1299px) and (min-height:901px) {

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 20px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 30px;
        font-weight: 400;
        line-height: 28px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 270px !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 75px !important;
        width: 220px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 80% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 26px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 22px;
        font-style: normal;
        font-size: 16px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 20px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 20px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 26px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 51px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 20px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 250px;
        width: 275px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }

    .minimisLogo {
        width: 95%;
        height: auto;
    }
}

@media screen and (min-width:1100px) and (max-width:1299px) and (min-height:800px) and (max-height:900px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 10%;
        padding-top: 10%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 21px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 27px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 16px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 27px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 270px !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 75px !important;
        width: 220px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 80% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 22px;
        font-size: 19px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 20px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 16px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 16px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 16px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 250px;
        width: 275px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1100px) and (max-width:1299px) and (min-height:731px) and (max-height:799px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 21px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .textDiv img {
        height: 25px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 450px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 27px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 235px !important;
        height: 239px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 75px !important;
        width: 220px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 80% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 18px;
        font-size: 19px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 18px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 16px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 16px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 14px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 250px;
        width: 275px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1100px) and (max-width:1299px) and (min-height:690px) and (max-height:730px) {

    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        position: relative;
        width: 5.4vw !important;
    }

    .textContainer .textDiv img {
        height: 25px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 20px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 430px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 12px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 235px !important;
        height: 239px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 75px !important;
        width: 220px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 80% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 17px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 16px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 16px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 25px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 14px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 250px;
        width: 275px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1100px) and (max-width:1299px) and (min-height:600px) and (max-height:689px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .textContainer .textDiv img {
        height: 23px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 12px;
    }

    .bottom-area {
        padding-bottom: 12px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 24px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 24px !important;
    }

    .boxes .glassImg {
        width: 380px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 16px;
        font-size: 14px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 24px !important;
    }

    .boxes .mobileImg {
        width: 420px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -15px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 12px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 22px;
        font-weight: 400;
        line-height: 12px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 195px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 75px !important;
        width: 220px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 80% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 17px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 14px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 12px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 12px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 10px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 250px;
        width: 275px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1100px) and (max-width:1299px) and (max-height:599px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 22px;
        width: 150px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.1% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 30px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 4.1% !important;
        font-size: 15px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 12px;
    }

    .bottom-area {
        padding-bottom: 12px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 24px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 24px !important;
    }

    .boxes .glassImg {
        width: 380px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 16px;
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 24px !important;
    }

    .boxes .mobileImg {
        width: 410px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -20px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 12px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 20px;
        font-weight: 400;
        line-height: 8px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 59%;
        min-height: 165px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 41%;
        padding-top: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 70px !important;
        width: 190px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 80% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 16px;
        font-size: 17px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 14px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 55%;
        min-height: 185px !important;
        height: 190px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 215px;
        width: 260px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1000px) and (max-width:1099px) and (min-height:921px) {

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 100px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 20px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 28px;
        font-weight: 400;
        line-height: 26px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 270px !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 70px !important;
        width: 195px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 26px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 22px;
        font-style: normal;
        font-size: 16px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 20px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 20px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 26px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 51px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 24px 0px 0px 24px;
        display: flex;
        flex-direction: column !important;
        gap: 20px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 220px;
        width: 245px;
        margin-top: -30px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1000px) and (max-width:1099px) and (min-height:800px) and (max-height:920px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 10%;
        padding-top: 10%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.1% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 16px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 26px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 270px !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 70px !important;
        width: 195px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 24px;
        font-size: 21px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 21px;
        font-style: normal;
        font-size: 16px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 18px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 18px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 22px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 16px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 258px !important;
        height: 258px !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 220px;
        width: 245px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1000px) and (max-width:1099px) and (min-height:731px) and (max-height:799px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 95% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 225px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 70px !important;
        width: 195px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 22px;
        font-size: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 19px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 14px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 14px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 25px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 14px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 215px !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 220px;
        width: 245px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1000px) and (max-width:1099px) and (min-height:690px) and (max-height:730px) {

    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 80px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 430px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 52%;
        min-height: 225px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 30px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 70px !important;
        width: 195px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 22px;
        font-size: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 14px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 12px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 12px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 16px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 25px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: 215px !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 220px;
        width: 245px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1000px) and (max-width:1099px) and (min-height:600px) and (max-height:689px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 400px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 62%;
        min-height: 225px !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 38%;
        padding-top: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 160px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 22px;
        font-size: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 14px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 12px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 12px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 55%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 200px;
        width: 220px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:1000px) and (max-width:1099px) and (max-height:599px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156.3%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 250px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 100%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 12px;
    }

    .bottom-area {
        padding-bottom: 12px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 20px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 380px !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 12px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 8px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 21px;
        font-weight: 400;
        line-height: 10px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 62%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 38%;
        padding-top: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-start;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 160px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 16px;
        font-style: normal;
        font-size: 13px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column !important;
        gap: 8px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 55%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 200px;
        width: 220px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (min-height:950px) {

    .textContainer .flexDiv {
        padding-left: 6%;
        padding-top: 60px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 20px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 12px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: 270px !important;
        height: 279px !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 80px;
        padding-right: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 19px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 20px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 20px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 26px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 20px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 185px;
        width: 200px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 12px 12px;
        width: auto;
        border-radius: 2px;
        margin-right: 4px;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (min-height:850px) and (max-height:949px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 10%;
        padding-top: 10%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 156%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 100%;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 81.25%;
    }

    .textContainer .buttonDiv button {
        width: 40%;
        font-size: 13px;
        padding: 7px 13px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .bottom-area {
        padding-bottom: 16px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 400px !important;
        height: 100px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47.657vh !important;
        margin-top: 16px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47.657vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 14px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 80px;
        padding-right: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 90% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 18px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 14px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 14px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 40px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47.657vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 14px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 185px;
        width: 200px;
        margin-top: -20px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (min-height:790px) and (max-height:849px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 10%;
        padding-top: 10%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 150%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 60px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 100%;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 80%;
    }

    .textContainer .buttonDiv button {
        width: 35%;
        font-size: 13px;
        padding: 7px 10px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 60px;
        padding-right: 10px;
        rotate: -2deg !important;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 95% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 14px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 14px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 20px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 35px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 170px;
        width: 185px;
        margin-top: -0px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (min-height:760px) and (max-height:789px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 150%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 40px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 100%;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 80%;
    }

    .textContainer .buttonDiv button {
        width: 35%;
        font-size: 12.5px;
        padding: 7px 7px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 30px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 30px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 16px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 30px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 24px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 60px;
        padding-right: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 95% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 20px;
        font-size: 18px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 17px;
        font-style: normal;
        font-size: 15px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 12px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 35px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 170px;
        width: 185px;
        margin-top: -0px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (min-height:620px) and (max-height:759px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 8%;
        padding-top: 8%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 130%;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {
        position: absolute;
        bottom: 30px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 100%;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 80%;
    }

    .textContainer .buttonDiv button {
        width: 35%;
        font-size: 13px;
        padding: 7px 10px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 20px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 20px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 20px !important;
    }

    .boxes .mobileImg {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 10px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 20px;
        font-weight: 400;
        line-height: 10px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 60px;
        padding-right: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 95% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 16px;
        font-size: 16px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 16px;
        font-style: normal;
        font-size: 13px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 8px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 8px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 35px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 10px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 170px;
        width: 190px;
        margin-top: -0px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (min-height:600px) and (max-height:619px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 130%;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {

        position: absolute;
        bottom: 30px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 100%;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 80%;
    }

    .textContainer .buttonDiv button {
        width: 30%;
        font-size: 13px;
        padding: 7px 10px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .BoxDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 14px;
    }

    .bottom-area {
        padding-bottom: 14px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 20px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 20px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 20px !important;
    }

    .boxes .mobileImg {
        width: 90% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        margin-top: -10px;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 14px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 8px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 18px;
        font-weight: 400;
        line-height: 8px;
        text-transform: uppercase;
        margin-bottom: 8px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 60px;
        padding-right: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 95% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 14px;
        font-size: 15px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 15px;
        font-style: normal;
        font-size: 13px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 10px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 10px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 8px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 8px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 35px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 8px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 170px;
        width: 190px;
        margin-top: -0px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width:769px) and (max-width:999px) and (max-height:599px) {
    * {
        font-size: 100%;
        margin: 0px;
        padding: 0px;
    }

    p {
        margin-bottom: 0px !important;
    }

    .row {
        --bs-gutter-x: 0rem !important;
    }

    .landingPage {
        background: #ecebe5;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .textContainer {
        width: 50.624vw !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .imagesPart {
        width: 43.976vw !important;
    }

    .iconsDiv {
        width: 5.4vw !important;
        position: relative;
    }

    .textContainer .flexDiv {
        padding-left: 5%;
        padding-top: 5%;
    }

    .textContainer .textDiv {
        border-left: 1px solid #9b9a96;
        padding-left: 2.38%;
    }

    .textContainer .textDiv h6 {
        font-family: 'Inter', sans-serif;
        font-size: 130%;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 400;
    }

    .textContainer .textDiv img {
        height: 24px;
        width: 160px;
        flex-shrink: 0;
        fill: var(--primary-black, #2E2E2E);
        stroke-width: 1px;
        stroke: #333;
    }

    .textContainer .textDiv p,
    .textContainer .textDiv span {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 17px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv {

        position: absolute;
        bottom: 30px;
    }

    .textContainer .buttonDiv span,
    .textContainer .buttonDiv p {
        font-family: 'Inter', system-ui;
        padding-left: 5px;
        padding-top: 3.9% !important;
        font-size: 16px;
        font-weight: 100;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        opacity: 0.95;
    }

    .textContainer .buttonDiv input {
        background: #dcdbd5;
        outline: none;
        border: none;
        padding: 5px 10px;
        width: 100%;
        border-radius: 2px;
        margin-right: 4px;
    }

    .textContainer .buttonDiv input::placeholder {
        font-size: 80%;
    }

    .textContainer .buttonDiv button {
        width: 35%;
        font-size: 13px;
        padding: 7px 10px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    /* ############### side box styling start #############  */

    .BoxDiv {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        height: 47vh !important;
        margin-top: 10px;
    }

    .bottom-area {
        padding-bottom: 10px !important;
    }

    .boxes .main-glass-img {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-image: url(/src/assests/images/background-frame.png) !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        padding: 0px 20px !important;
    }

    .boxes p {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        animation: fadeIn 3s ease forwards;
        display: block;
        padding-top: 20px !important;
    }

    .boxes .glassImg {
        width: 350px !important;
        height: 90px !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    .boxes a {
        color: var(--primary-black, #ECEBE5);
        font-family: 'Inter', sans-serif;
        line-height: 19px;
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
    }

    .boxes .launcherImg {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        background-image: url('../../assests/images/minimis-mobile-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        animation: fadeIn 3s ease forwards;
        padding: 0px 20px !important;
    }

    .boxes .mobileImg {
        width: 85% !important;
        height: auto !important;
        object-fit: contain;
        position: absolute;
        top: 54%;
        left: 51%;
        transform: translate(-50%, -50%);
        animation: fadeIn 3s ease forwards;
    }

    #icons {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .iconsDiv div {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 0px;
    }

    hr {
        margin: 0 0 !important;
        color: #2E2E2E !important;
        border: 0 !important;
        border-top: 0 !important;
        opacity: 0.45 !important;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* pages.css */

    .BoxDiv {
        overflow: hidden;
    }

    .boxes-div {
        height: 47vh !important;
        margin-top: 10px;
    }

    .glassHoverDiv {
        min-width: auto !important;
        background: #DAD8CC;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        overflow: hidden;
        gap: 5px !important;
        box-sizing: border-box;
        border-left: 2px solid #333;
    }

    .glassHoverDiv h2,
    .launcherHoverDiv h2 {
        color: var(--primary-black, #2E2E2E);
        font-family: 'Inter', system-ui;
        font-size: 18px;
        font-weight: 400;
        line-height: 8px;
        text-transform: uppercase;
        margin-bottom: 5px;
        animation: fadeIn 3s ease forwards;
    }

    .glassHoverDiv .hoverText {
        width: 53%;
        min-height: auto !important;
        height: auto !important;
        max-height: auto;
    }

    .glassHoverDiv div .glassImg1 {
        width: 47%;
        padding-top: 35px;
        padding-right: 10px;
        display: flex;
        align-items: start;
        justify-content: flex-end;
    }

    .glassHoverDiv .glassImg1 img {
        height: 55px !important;
        width: 140px !important;
        object-fit: cover;
        animation: fadeIn 3s ease forwards;
    }

    .paragraph-heading {
        width: 95% !important;
    }

    .paragraph-heading p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #333);
        font-weight: 300;
        line-height: 14px;
        font-size: 15px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText p,
    .glassHoverDiv .hoverText p {
        font-family: 'Inter', system-ui;
        color: var(--primary-black, #2E2E2E);
        font-weight: 300;
        line-height: 15px;
        font-style: normal;
        font-size: 12.5px;
        animation: fadeIn 3s ease forwards;
        opacity: 0.7;
    }

    .hoverText .p1 {
        margin-top: 0px;
    }

    .hoverText .p2 {
        margin-top: 8px;
    }

    .hoverText .p3 {
        margin-top: 0px;
    }

    .hoverText .p4 {
        margin-top: 8px;
    }

    .glassHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 8px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 14px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv .hoverText button {
        outline: none;
        border: none;
        font-family: 'Inter', system-ui;
        padding: 8px;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        background: var(--primary-black, #2E2E2E);
        font-weight: 500;
        margin-top: 35px !important;
        animation: fadeIn 3s ease forwards;
    }

    .launcherHoverDiv {
        min-width: auto !important;
        height: 47vh;
        padding: 18px 0px 0px 18px;
        display: flex;
        flex-direction: column !important;
        gap: 8px !important;
        box-sizing: border-box;
        overflow: hidden;
        background: #DAD8CC;
        border-left: 2px solid #333;
    }

    .launcherHoverDiv div .hoverText {
        width: 49%;
        min-height: auto !important;
        height: auto !important;
        max-height: 100%;
    }

    .launcherHoverDiv div .launcherImg1 {
        width: 51%;
        display: flex;
        justify-content: flex-end;
    }

    .launcherHoverDiv .launcherImg1 .phone-mockup-class {
        background-image: url("../../assests/images/mobile-text-img-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 170px;
        width: 190px;
        margin-top: -0px;
        animation: fadeIn 3s ease forwards;
    }
}

@media screen and (min-width: 500px) {
    .LauncherMobileDesign {
        height: 570px;
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 499px) {
    .modal-dialog {
        width: auto !important;
    }

    .LauncherMobileDesign {
        height: 530px;
    }

    .glassMobileDesign {
        height: 390px;
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 425px) {
    .LauncherMobileDesign {
        height: 495px;
    }

    .glassMobileDesign {
        height: 400px;
    }

    .modal-dialog {
        width: auto !important;
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 389px) {
    .LauncherMobileDesign {
        height: 480px;
    }

    .glassMobileDesign {
        height: 410px;
    }

    .modal-dialog {
        width: auto !important;
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}